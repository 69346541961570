const companyTheme = {
  'aim': {
    name: "AIM",
    backgroundColor: '#f7f7f8',
    companyIdentityColor: '#d31145',
    collection: "aia_eb_v1_600_200",
    companyBackgroundName: "var(--aia-eb-bg-image)",
    filter: "invert(44%) sepia(94%) saturate(1397%) hue-rotate(132deg) brightness(97%) contrast(98%)"
  },
  'sunlife': {
    name: 'Sun Life',
    backgroundColor: '#fff8e0',
    companyIdentityColor: '#fff8e0',
    companyBackgroundName: "var(--sunlife-bg-image)",
    filter: "invert(44%) sepia(94%) saturate(1397%) hue-rotate(132deg) brightness(97%) contrast(98%)"
  },
  smartone: {
    name: 'SmarTone',
    backgroundColor: '#f7f7f8',
    companyIdentityColor: '#ff0000',
    companyBackgroundName: 'var(--smartone-bg-image)',
    filter:
      'invert(12%) sepia(99%) saturate(4123%) hue-rotate(335deg) brightness(98%) contrast(95%)',
  },
  'aia': {
    name: "AIA",
    backgroundColor: '#f7f7f8',
    companyIdentityColor: '#d31145',
    companyBackgroundName: "var(--aia-my-bg-image)",
    collection: "my_aia_v5_600_200",
    filter: "invert(12%) sepia(99%) saturate(4123%) hue-rotate(335deg) brightness(98%) contrast(95%)"
  },
  'axa': {
    name: "AXA",
    backgroundColor: '#f7f7f8',
    companyIdentityColor: '#01028f',
    companyBackgroundName: "var(--aia-axa-bg-image)",
    collection: "",
    filter: "invert(12%) sepia(99%) saturate(4123%) hue-rotate(335deg) brightness(98%) contrast(95%)"
  },
  'bea': {
    name: 'BEA',
    backgroundColor: '#f7f7f8',
    companyIdentityColor: '#d31145',
    companyBackgroundName: "var(--bea-bg-image)",
    collection: "",
    filter: "invert(12%) sepia(99%) saturate(4123%) hue-rotate(335deg) brightness(98%) contrast(95%)"
  },
  'aiahk': {
    name: "AIA",
    backgroundColor: '#f7f7f8',
    companyIdentityColor: '#d31145',
    companyBackgroundName: "var(--aia-agency-bg-image)",
    collection: "aia_122_docs_v1_600_200",
    filter: "invert(12%) sepia(99%) saturate(4123%) hue-rotate(335deg) brightness(98%) contrast(95%)"
  },
  'nwlife': {
    name: "New World Life",
    backgroundColor: '#efefef',
    companyIdentityColor: '#009188',
    filter: "invert(33%) sepia(96%) saturate(1305%) hue-rotate(151deg) brightness(90%) contrast(101%)"
  },
  'gta': {
    name: "SwimRun Association",
    backgroundColor: '#efefef',
    companyIdentityColor: '#877547',
    filter: "invert(45%) sepia(52%) saturate(326%) hue-rotate(5deg) brightness(91%) contrast(91%)"
  },
  'swire': {
    name: "Swire Properties",
    backgroundColor: '#efefef',
    companyIdentityColor: '#877547',
    filter: "invert(45%) sepia(52%) saturate(326%) hue-rotate(5deg) brightness(91%) contrast(91%)"
  },
  'shk': {
    name: "Sun Hung Kai Properties",
    backgroundColor: '#efefef',
    companyIdentityColor: '#8f7e62',
    filter: 'invert(52%) sepia(34%) saturate(315%) hue-rotate(358deg) brightness(90%) contrast(84%)'
  },
  'clp': {
    name: "China Light and Power Company",
    backgroundColor: '#efefef',
    companyIdentityColor: '#0a59a4',
    filter: 'invert(22%) sepia(39%) saturate(4875%) hue-rotate(196deg) brightness(88%) contrast(92%)'
  },
  'eduhk': {
    name: "The Education University of Hong Kong",
    backgroundColor: '#efefef',
    companyIdentityColor: '#036436',
    filter: 'invert(22%) sepia(34%) saturate(4940%) hue-rotate(141deg) brightness(84%) contrast(98%)'
  },
  'esg': {
    name: "ESG",
    backgroundColor: '#f7f7f8',
    companyIdentityColor: '#02b98c',
    companyBackgroundName: "var(--esg-bg-image)",
    filter: "invert(44%) sepia(94%) saturate(1397%) hue-rotate(132deg) brightness(97%) contrast(98%)"
  },
};

export default companyTheme;