import { useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import theme from '#config/theme';

const useTheme = (defaultCompany) => {
  const { company = defaultCompany ?? 'aim' } = useParams();
  
  let isInit = false;
  let lCompany = company;
  window.aimChatbotConfig = { hideOnInit: false, title : 'Virtual Assistant' };
  window.aimChatbotConfig.fabImgUrl = "https://aim-my-gpt.ai-momentum.com/sources/avatar.svg";
  window.aimChatbotConfig.avatarUrl = "https://aim-my-gpt.ai-momentum.com/sources/avatar.svg";
  if (company.includes('eb')) {
    lCompany = 'aim';
  } else if (company.includes('bea')) {
    lCompany = 'bea';
  } else if (company.includes('aiahk')) {
    lCompany = 'aiahk';
  } else if (company.includes('sunlife')) {
    lCompany = 'sunlife';
    window.aimChatbotConfig.fabImgUrl = "https://aim-my-gpt.ai-momentum.com/sources/gold_avatar.svg";
    window.aimChatbotConfig.avatarUrl = "https://aim-my-gpt.ai-momentum.com/sources/gold_avatar.svg";
    // window.aimChatbotConfig.themeUrl = "https://aim-my-gpt.ai-momentum.com/odin/api/chats/get-theme?app_id=sunlife";
  } else if (company.includes('aia')) {
    lCompany = 'aia';
  } else if (company.includes('my')) {
    lCompany = 'aia';
  } else if (company.includes('axa')) {
    lCompany = 'axa';
  } else if (company.includes('smartone')) {
    window.aimChatbotConfig.hideOnInit = true;
    lCompany = 'smartone';
  } else if (company.includes('esg')) {
    lCompany = 'esg';
  }

  useLayoutEffect(() => {
    // append the script to the head
    if(!isInit) {
      const script = document.createElement('script');
      script.src = "https://odin-gpt.ai-momentum.com/sources/sdk/embed-fab.cjs.js";
      document.head.appendChild(script);
      isInit = true;
    }
    document.documentElement.style.setProperty('--background-color', (theme[lCompany]?.backgroundColor || theme['aim'].backgroundColor));
    document.documentElement.style.setProperty('--background-image', (theme[lCompany]?.companyBackgroundName || theme['aia'].companyBackgroundName));
    document.documentElement.style.setProperty('--company-identity-color', (theme[lCompany]?.companyIdentityColor || theme['aim'].companyIdentityColor));
    document.documentElement.style.setProperty('--svg-filter', (theme[lCompany]?.filter || theme['aim'].filter));
  }, [lCompany]);
};
export default useTheme;
